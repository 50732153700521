

.vmenu {
    > a {
        width: 100% !important;
    }

    .level3:hover {
        background-color: $gray-500 !important;
    }
}


.level2highlight {
    background-image: none !important;
    background-color: $orange !important;
    color: $white !important;
}