

body.page-front,
body.page-centered {
    background: linear-gradient(transparent, transparent 50%, #e8ebed 50%, #e8ebed), center / cover no-repeat url("https://stgsitdsmyacprodeuw001.blob.core.windows.net/assets/FrontEnd3/IMAGE/background-cubes.png");
}

body {
    //margin-top: map-get($spacers, 5);
    // margin-top: $spacer * 5;
}



.layout-body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    // background: linear-gradient(#121D36, #121D36 50%, #e8ebed 50%, #e8ebed), url('https://learn.graphisoft.com/assets/i/background-cubes.png') 100%;
    
}


.layout-content {
    flex: 1;

    // &.container {
    //     margin-top: map-get($spacers, 5);
    //     margin-bottom: map-get($spacers, 5);
    // }

    font-size: 0.75rem;
    padding-top: $spacer * 5;
}


.page-front,
.page-centered {
    .layout-content {
        display: flex;
    }
}



.layout-footer {
    a {
        color: $gray-700;
    }
}

.navbar-brand {
    svg {
        height: 16px;
        // margin-top: -4px;

        path {
            fill: #fff !important;
            fill-rule: evenodd;
        }
    }
}

#screen {
    width: 100%;

    @include media-breakpoint-up(sm) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 85%;
    }
    
    @include media-breakpoint-up(lg) {
        width: 80%;
    }
    
    @include media-breakpoint-up(xl) {
        width: 75%;
    }
}

#site-name {
    font-size: $font-size-lg;
}